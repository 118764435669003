#blog-post {

	.section-hero .sub-caption h1 {
		padding-top: 46px;
	}

	.section-post {
		padding: 50px 0;

		.sub-content {

			.sub-slider {
				width: 520px;
				height: 218px;
				float: left;
				display: block;
				border: 2px solid $color-white;
				background: $color-white;
				position: relative;

				ul.list-slides {
					margin: 0;
					padding: 0;
					list-style: none;

					li {
						margin: 0;
						padding: 0;
					}
				}

				ul.slick-dots {
					list-style: none;
					margin: 10px 0 0 0;
					padding: 0;

					li {
						margin: 0 5px;
						padding: 0;
						display: inline-block;

						button {
							@extend .nofocus;
							@extend .noselect;
							width: 10px;
							height: 10px;
							margin: 0;
							padding: 0;
							border: none;
							background: $color-primary;
							text-indent: -9999px;
							border-radius: 50%;
						}

						button:hover ,
						&.slick-active button {
							background: $color-secondary;
						}
					}
				}
			}

			.sub-copy {
				width: 560px;
				float: right;
			}
		}
	}

	.section-related.post-page {
		width: 100%;
		text-align: center;
		margin-top: 40px;

		h2 {
			margin: 0 0 75px 0;

			&:before,
			&:after {
				width: 240px;
				height: 10px;
				display: inline-block;
				margin: 0 25px;
				border-bottom: 1px dotted $color-borders;
				content: " ";
				position: relative;
				top: -10px;
			}
		}
	}
}