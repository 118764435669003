/* COLOURS */

$color-black: #000000;
$color-white: #ffffff;

$color-body: $color-white;

$color-neutral: #F5F2F5;
$color-neutral-dark: #969696;

$color-overlay-dark: #10171E;

$color-borders: #aaaaaa;
$color-borders-light: #C7C7C7;
$color-borders-lighter: #E8EBEB;

$color-text: #646464;
$color-text-light: #939393;

$color-headings: $color-black;

$color-error: #ff0000;

$color-primary: #E60A0A;
$color-secondary: #039BE5;

$color-link: $color-primary;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

$color-validation-error: #cd0000;
$color-validation-success: #4eb73c;

$color-social-facebook: #3B5998;

/* FONTS */

$font-standard: proxima-nova, sans-serif;

// Define font base
$font-base: 14;

$font-size-base: $font-base * 1px;
$font-size-standard: (14/$font-base) * 1rem; // 14px
$font-size-tiny: (12/$font-base) * 1rem; // 12px
$font-size-small: (13/$font-base) * 1rem; // 13px
$font-size-medismall: (15/$font-base) * 1rem; // 15px
$font-size-med: (16/$font-base) * 1rem; // 16px
$font-size-medilarge: (17/$font-base) * 1rem; // 17px
$font-size-large: (18/$font-base) * 1rem; // 18px
$font-size-larger: (20/$font-base) * 1rem; // 20px

$font-size-h1: (30/$font-base) * 1rem; // 30px
$font-size-h2: (22/$font-base) * 1rem; // 22px
$font-size-h3: $font-size-larger; // 20px
$font-size-h4: $font-size-large; // 18px
$font-size-h5: $font-size-med; // 16px
$font-size-h6: $font-size-standard; // 14px

$font-size-hero-standard: $font-size-larger; // 20px
$font-size-hero-small: (24/$font-base) * 1rem; // 24px
$font-size-hero-smallish: (32/$font-base) * 1rem; // 32px
$font-size-hero-med: (48/$font-base) * 1rem; // 48px

$font-weight-ultra-light: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-light;
$headings-color: $color-headings;
$headings-line-height: 1.4;

$line-height-base: 1.55;
$line-height-med: 1.85;
$line-height-large: 2;

/* STRUCTURE */

$viewport-width: 100%;
$container-width: 90%;
$container-max-width: 1090px;
$container-large: 1280px;
$container-small: 900px;
$container-pad-mobile: 10px;

/* CONDITIONAL CHECK */

$brand-imported: true;