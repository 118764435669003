#header {

	@include mq(mobile) {
		padding: 8px 0;
	}

	.container {
		max-width: $container-large !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	#main-nav {

		@include mq(mobile) {
			max-height: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			z-index: 2;
			background: white;
			overflow: hidden;
			transition: all 0.5s ease;

			&.active {
				max-height: 100vh;
			}
		}

		ul.list-nav {
			@extend .list-default;
			display: flex;
			align-items: center;

			@include mq(mobile) {
				flex-direction: column;
				padding: 80px 0 0;
			}

			li {
				padding: 25px 0;
				position: relative;
				font-size: $font-size-small;
				font-weight: $font-weight-medium;
				text-transform: uppercase;
				letter-spacing: 2.5px;

				@include mq(mobile) {
					padding: 20px 0;
					font-size: $font-size-large;

				}

				&.mobile {
					display: none;

					@include mq(mobile) {
						display: block;
						margin-top: 25px;
					}
				}

				a {
					padding: 0 0 0 30px;
					vertical-align: middle;
					display: inline;
					color: $color-black;

					@include mq(mobile) {
						padding: 0;

						&.active {
							color: $color-primary;
						}
					}

					&:hover {
						color: $color-primary;
					}
				}

				&.active {

					&:before {
						content: '';
						display: block;
						width: 100%;
						height: 2px;
						background: $color-primary;
						position: absolute;
						left: 0;
						bottom: 0;

						@include mq(mobile) {
							display: none;
						}
					}
				}


				&:not(.last):after {
					width: 1px;
					content: '';
					height: 20px;
					display: inline-block;
					vertical-align: middle;
					background: $color-borders-light;
					margin: 0 0 0 30px;

					@include mq(mobile) {
						display: none;
					}
				}

				&.last:after {
					content: '';
					display: inline-block;
					margin: 0 0 0 30px;

					@include mq(mobile) {
						display: none;
					}
				}
			}
		}
	}

	.sub-social {
		@include mq(mobile) {
			display: none;
		}
	}

	.sub-mobile-menu {
		display: none;
		cursor: pointer;

		@include mq(mobile) {
			display: block;
			z-index: 3;
		}

		&.active {

			&:before {
				transform: translate(1px,4.5px) rotate(-45deg);
			}

			&:after {
				transform: translate(1px,-5.5px) rotate(45deg)
			}
		}

		&:before,
		&:after	{
			content: '';
			height: 2px;
			width: 25px;
			background: $color-primary;
			display: block;
			margin: 0 0 8px;
			transition: all 0.2s ease;
		}

		&:after {
			margin: 0;
		}
	}
}

#about,
#contact {
	#header {
		border-bottom: 1px solid $color-borders-light;
	}
}