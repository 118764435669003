.svg-icon {
	color: #ff99f8;
	display: inline-block;
	vertical-align: middle;

	&.logo-main {
		height: 48px;
		width: 156px;

		@include mq(mobile) {
			height: 28px;
			width: 91px;
		}
	}

	&.icon-at {
		height: 18px;
		width: 18px;
		color: $color-link;
	}

	&.icon-phone {
		height: 18px;
		width: 18px;
		color: $color-link;
	}

	&.icon-facebook {
		height: 31px;
		width: 31px;
		color: $color-social-facebook;

		@include mq(mobile) {
			height: 40px;
			width: 40px;
		}
	}

	&.icon-calendar {
		height: 24px;
		width: 24px;
		color: $color-primary;
	}

	&.icon-duration {
		height: 24px;
		width: 24px;
		color: $color-primary;
	}

	&.icon-pound {
		height: 24px;
		width: 24px;
		color: $color-primary;
	}
}