@mixin columns(
	$width-gutter: 2%,
	$selector-column: article,
	$prefix-sizing: 'article',
	$order-swap: false,
	$combine-container: false,
	$collapse-mobile: true
) {
	@if $combine-container {
		@extend .container;
	} @else {
		width: 100%;
	}

	@if $order-swap {
		@extend .clearfix;
	}

	& > #{$selector-column} {
		margin: 0 $width-gutter / 2;
		display: inline-block;
		vertical-align: top;

		@if $order-swap {
			&:first-of-type {
				margin-right: 0;
				float: right
			}

			&:last-of-type {
				margin-left: -1 * ($width-gutter / 2);
			}
		} @else {
			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-right: -1 * ($width-gutter / 2);
			}
		}



		&.#{$prefix-sizing}-min {
			width: 19.4% - ($width-gutter / 2);
		}

		&.#{$prefix-sizing}-tiny {
			width: 23.5% - 3*($width-gutter / 4);
		}

		&.#{$prefix-sizing}-small {
			width: 33% - ($width-gutter / 2);
		}

		&.#{$prefix-sizing}-medium {
			width: 50% - ($width-gutter / 2);
		}

		&.#{$prefix-sizing}-large {
			width: 75% - ($width-gutter / 4);
		}

		&.#{$prefix-sizing}-full {
			width: 100%;
		}

		@if ($collapse-mobile = true) {
			@include mq(mobile) {
				&.#{$prefix-sizing}-min,
				&.#{$prefix-sizing}-tiny,
				&.#{$prefix-sizing}-small,
				&.#{$prefix-sizing}-medium,
				&.#{$prefix-sizing}-large,
				&.#{$prefix-sizing}-full {
					display: block;
					width: 100%;
					margin-left: 0;
					margin-right: 0;
					float: none;
				}
			}
		}
	}
}


@include default-style {
	.section-columns {
		@include columns;

		article img {
			max-width: 100%;
			height: auto;
		}
	}
}
