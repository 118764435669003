.section-list-posts {

	ul.list-posts {
		@include columns($collapse-mobile: true);
		@extend .list-default;

		& > li {
			margin-bottom: 60px;
			background: $color-white;

			.sub-media {
				display: block;
				width: 100%;

				img {
					width: 100%;
					height: auto;
				}
			}

			.sub-detail {
				width: 90%;
				margin: 0 auto;

				h3 {
					margin: 15px 0;
				}
			}
		}
	}
}

.section-list-gallery {
	// TODO

	ul.list-gallery {
		@extend .list-default;

		& > li {

		}
	}
}