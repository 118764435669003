// Clearfix
@mixin clearfix {
	zoom: 1;

	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

.clearfix {
	@include clearfix;
}

// Remove selectability
.noselect {
	user-select: none;
}

// Remove focus styles
.nofocus {
	&:focus {
		outline-style: none !important;
		box-shadow: none !important;
	}
}

// Default list styles
.list-default {
	margin: 0;
	padding: 0;
	list-style: none !important;

	& > li,
	& .slick-slide {
		margin: 0;
		padding: 0;
	}
}

@function reduce-qsl($list) {
	$query-string: "";
	$len: length($list);

	@for $i from 1 through $len {
		$item: nth($list, $i);
		$selector: "";
		$looked-up: true;

		@if map-has_key($mq-defs, $item) {
			$selector: map-get($mq-defs, $item);
		} @else {
			$selector: $item;
			$looked-up: false;
		}

		@if($item != plus and $i != 1 and nth($list, $i - 1) != plus and $looked-up == true) {
			$query-string: "#{$query-string} and #{$selector}";
		} @else if($item == plus) {
			$query-string: "#{$query-string}, ";
		} @else {
			$query-string: "#{$query-string} #{$selector}";
		}
	}

	// Remove leading ' '
	$query-string: str-slice($query-string, 2);

	@return $query-string;
}

@mixin mq($queries) {
	$query-string: "";
	$qsl: ();

	@each $query in $queries {
		$qsl: append($qsl, "#{reduce-qsl($query)}, ");
	}

	$query-string: "#{reduce-qsl($qsl)}";
	$query-string: str-slice($query-string, 1, -3);

	@media only screen and #{$query-string} {
		@content;
	}
}

@mixin default-style() {
	@if $generate-default-styles {
		@content;
	}
}
