ul.slick-slider li {
	outline: none !important;
}
ul.slick-dots {
	@extend .list-default;
	display: flex;

	li {
		width: 100%;
		height: 3px;
		background: $color-neutral-dark;
		cursor: pointer;

		@include mq(mobile) {
			height: 2px;
		}

		&.slick-active {
			background: $color-primary;
		}

		button {
			display: none;
		}
	}
}