#blog,
#blog-post {

	.section-hero {
		width: 100%;
		margin: 0;
		text-align: center;
		background: $color-neutral;
		position: relative;

		.sub-gradient {
			width: 100%;
			height: 100%;
			background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
			opacity: 0.8;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		.sub-overlay {
			width: 100%;
			height: 100%;
			background: $color-black;
			opacity: 0.2;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}

		ul.list-slides {
			list-style: none;
			margin: 0;
			padding: 0;
			z-index: 3;

			li {
				margin: 0;
				padding: 0;

				&.blur img {
					-webkit-filter: blur(15px);
					-moz-filter: blur(15px);
					-ms-filter: blur(15px);
					-o-filter: blur(15px);
					filter: blur(15px);
					transform: scale(1.8);
				}
			}
		}

		.sub-background {
			width: 100%;
			height: 100%;
			background-size: cover;
			opacity: 0.3;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}

		.sub-caption {
			width: 745px;
			margin: 0 auto;
			padding: 100px 0 80px 0;
			position: relative;
			z-index: 2;

			.sub-featured {
				margin-bottom: 20px;
				font-size: $font-size-med;
				font-weight: $font-weight-bold;
				color: $color-white;
				text-transform: uppercase;
			}

			h1,
			h2 {
				margin: 0 0 35px 0;
				line-height: 1;
				font-size: $font-size-hero-med;
				font-weight: $font-weight-bold;
				color: $color-white;
				text-transform: uppercase;
				z-index: 2;

				a {
					color: $color-white;
					text-decoration: none;
				}
			}

			.sub-posted {
				font-size: $font-size-small;
				font-weight: $font-weight-regular;
				color: $color-white;
			}

			.btn {
				padding: 6px 47px;
				font-weight: $font-weight-regular;
			}
		}

		ul.slick-dots {
			width: 100%;
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;
			position: absolute;
			bottom: 20px;
			left: 0;

			li {
				width: 10px;
				height: 10px;
				margin: 0 5px;
				padding: 0;
				display: inline-block;

				button {
					@extend .nofocus;
					@extend .noselect;
					width: 10px;
					height: 10px;
					margin: 0;
					padding: 0;
					border: none;
					background: $color-primary;
					text-indent: -9999px;
					border-radius: 50%;
				}

				button:hover,
				&.slick-active button {
					background: $color-white;
				}
			}
		}
	}
}