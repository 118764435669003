#home {

	.section-hero {

		ul.list-hero {
			@extend .list-default;
			height: 440px;

			@include mq(mobile) {
				height: 345px;
			}

			.slick-track {
				height: 440px;

				@include mq(mobile) {
					height: 345px;
				}
			}

			li {
				position: relative;
				background-position: center;
				background-size: cover;

				.sub-overlay {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 90%;
					background: linear-gradient(to bottom, rgba($color-black,0) 0%,rgba($color-black,0.8) 100%);
					z-index: 0;
				}

				.sub-content {
					position: absolute;
					left: 0;
					right: 0;
					z-index: 1;
					bottom: 75px;

					@include mq(mobile) {
						bottom: 30px;
					}

					.sub-copy {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: flex-end;

						@include mq(mobile) {
							justify-content: center;
							flex-direction: column;
							text-align: center;

							.sub-title {
								width:  100%;
							}
						}

						h1,h2,h3,h4,h5 {
							font-size: $font-size-hero-med;
							font-weight: $font-weight-bold;
							color: $color-white;
							margin: 0;

							@include mq(mobile) {
								font-size: $font-size-h1;
								margin: 0 0 20px;
							}
						}

						p {
							color: $color-white;
							font-size: $font-size-h2;
							margin: 0;

							@include mq(mobile) {
								font-size: $font-size-large;

							}
						}

						.btn.btn-primary {

							@include mq(mobile) {
								margin: 30px auto 0;
								padding: 13px 85px;
							}
						}
					}
				}
			}
		}
	}

	.section-intro {
		text-align: center;
		padding: 70px 0 100px;
		max-width: $container-small !important;

		@include mq(mobile) {
			padding: 50px 10px 80px;
		}

		h3 {
			margin: 0 0 40px;
			font-size: $font-size-med;
			font-weight: $font-weight-bold;
			letter-spacing: 3.5px;
			text-transform: uppercase;

			@include mq(mobile) {
				margin: 0 0 15px;
			}
		}

		p {
			font-size: $font-size-large;
			margin: 0 0 45px;
			line-height: $line-height-large;

			@include mq(mobile) {
				font-size: $font-size-medilarge;
			}
		}

		.btn.btn-primary {
			font-size: $font-size-small;
			padding: 11px 45px;

			@include mq(mobile) {
				font-size: $font-size-medismall;
			}
		}
	}

	.section-featured-projects {
		background: $color-neutral;
		padding: 70px 0 100px;

		@include mq(mobile) {
			padding: 50px 0;
		}

		.sub-title {
			text-align: center;

			h3 {
				margin: 0 0 60px;
				font-size: $font-size-med;
				font-weight: $font-weight-bold;
				letter-spacing: 3.5px;
				text-transform: uppercase;

				@include mq(mobile) {
					margin: 0 0 50px;
				}
			}
		}
	}

	.section-testimonial {
		background-position: center;
		background-size: cover;
		height: 500px;
		position: relative;

		.sub-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba($color-overlay-dark, 0.85);

		}

		.sub-content {
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			position: relative;
			z-index: 1;

			h4 {
				font-size: $font-size-med;
				margin: 0 0 40px;
				color: $color-white;
				letter-spacing: 3px;
				font-weight: $font-weight-bold;
				text-transform: uppercase;
			}

			ul.list-testimonial {
				@extend .list-default;
				color: $color-white;

				li.item-testimonial {

					.sub-copy {
						max-width: $container-small;
						margin: 0 auto;
						text-align: center;

						p {
							font-size: $font-size-larger;
							line-height: $line-height-large;
							font-weight: $font-weight-light;
							margin: 0;

							@include mq(mobile) {
								font-size: $font-size-large;
							}
						}
					}

					ul.list-details {
						margin: 35px 0 60px;
						@extend .list-default;
						font-size: 0;

						@include mq(mobile) {
							margin: 40px 0;
						}

						li {
							display: inline-block;
							vertical-align: middle;
							font-size: $font-size-standard;

							@include mq(mobile) {
								font-size: $font-size-small;
							}

							&:first-child:after {
								content: '';
								height: 10px;
								width: 1px;
								vertical-align: middle;
								display: inline-block;
								background: $color-primary;
								margin: 0 11px;
							}
						}
					}
				}
			}
		}
	}

	.section-facebook {
		padding: 75px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include mq(mobile) {
			display: none;
		}

		.sub-title {
			max-width: 465px;

			h3 {
				margin: 0;
				font-size: $font-size-med;
				font-weight: $font-weight-bold;
				letter-spacing: 3.5px;
				text-transform: uppercase;

				&:after {
					content: '';
					height: 4px;
					width: 90px;
					display: block;
					margin: 35px 0 40px;
					background: $color-primary;
				}
			}

			p {
				font-size: $font-size-hero-smallish;
				line-height: $headings-line-height;
				font-weight: $font-weight-ultra-light;
				margin: 0;
			}
		}
	}
}