.section-cards {
	@include columns;

	article {
		padding: 20px 0;
		justify-content: center;
		background: $color-white;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
		text-align: center;

		.sub-actions {
			width: 100%;
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px solid $color-borders-light;
			display: block;

			.btn {
				margin: 0 1%;
			}

			&.mod-left {
				padding-right: 20px;
				padding-left: 20px;
				text-align: left;
			}
		}

		&.mod-media {

			.sub-media {
				position: relative;
				top: -20px;

				img {
					max-width: 100%;
					height: auto;
				}
			}
		}

		&.mod-left {
			text-align: left;

			.sub-copy,
			.sub-actions {
				padding-right: 20px;
				padding-left: 20px;
			}
		}
	}
}