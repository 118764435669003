.table-cards {
	width: 100%;

	tr {
		text-align: left;

		th {
			padding: 20px;
			border-bottom: 10px solid $color-body;
			font-size: $font-size-small;
			font-weight: $font-weight-semibold;
			background: $color-text;
			color: $color-white;

			&.mod-right {
				text-align: right;
			}
		}

		td {
			padding: 25px 20px;
			border-bottom: 10px solid $color-body;
			background: $color-white;

			&.mod-right {
				text-align: right;
			}
		}
	}

	&.mod-short tr {

		th {
			border-bottom: 5px solid $color-body;
		}

		td {
			padding: 10px 20px;
			border-bottom: 5px solid $color-body;
		}
	}
}