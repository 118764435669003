#footer {
	align-items: center;
	border-top: 1px solid $color-borders-lighter;
	display: flex;
	justify-content: center;
	padding: 50px 0 80px;

	@include mq(mobile) {
		display: block;
		padding: 50px 0;
	}

	.sub-award-image {

		.img-award {
			max-height: 198px;
			cursor: pointer;
			display: block;

			@include mq(mobile) {
				margin: 0 auto;
			}
		}
	}

	.sub-footer-links-wrapper {
		width: 810px;

		@include mq(mobile) {
			width: 100%;
		}

		.section-contact-info {
			@extend .container;
			max-width: 700px !important;

			ul.list-info {
				@extend .list-default;
				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-direction: column;
					align-items: center;
				}

				li {
					font-size: $font-size-large;
					color: $color-black;

					@include mq(mobile) {
						font-size: $font-size-med;
						margin: 0 0 25px;
					}

					a {
						font-size: $font-size-hero-small;
						font-weight: $font-weight-semibold;

						@include mq(mobile) {
							font-size: $font-size-h2;
						}

						&[href^="tel:"] {
							color: $color-black;
						}
					}
				}
			}
		}

		#footer-nav {
			margin: 30px 0 0;
			text-align: center;

			@include mq(mobile) {
				margin: 20px 0;
			}

			ul.list-nav {
				@extend .list-default;
				display: flex;
				justify-content: center;

				@include mq(mobile) {
					flex-wrap: wrap;
					align-items: center;

				}

				li {
					font-size: $font-size-standard;
					color: $color-text-light;

					@include mq(mobile) {
						font-size: $font-size-small;

						&:first-child {
							width: 100%;
							margin: 0 0 25px;

							&:after {
								content: none !important;
							}
						}
					}

					a {
						color: $color-text-light;

						&:hover {
							color: $color-primary;
						}
					}

					&:not(:last-child):after {
						content: '';
						height: 10px;
						vertical-align: middle;
						width: 1px;
						background: $color-text-light;
						display: inline-block;
						margin: 0 13px;
					}
				}
			}
		}
	}
}