.wrap-more {

	.sub-state {
		display: none;

		& ~ .btn-toggle::before {
			content: 'Show more';
		}

		&::checked ~ .sub-more-copy .sub-more-extended {
			height: auto;
		}

		&::checked ~ .btn-toggle::before {
			content: 'Show less';
		}
	}

	.sub-more-copy .sub-more-extended {
		height: 0;
		overflow: hidden;
	}
}