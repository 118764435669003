form {

	.form-element {
		position: relative;
		margin: 30px 0;

		input[type=text] {
			width: 100%;
			border: none;
			border-bottom: 2px solid $color-borders-light;
			transition: 0.2s ease;
			padding: 0 10px 0 150px;
			font-size: $font-size-large;
			color: $color-text;

			@include mq(mobile) {
				font-size: $font-size-med;
			}

			&:focus {
				border-color: $color-primary;

				+label {
					color: $color-primary;
					font-size: $font-size-med;
					font-weight: $font-weight-medium;
				}

				@include mq(mobile) {

					@include placeholder {
						color: $color-primary;
					}
				}
			}

			@include placeholder {
				color: $color-white;
			}

			@include mq(mobile) {
				padding: 0 10px;
				text-align: center;

				@include placeholder {
					color: $color-text;
				}
			}
		}

		label {
			position: absolute;
			bottom: 15px;
			left: 0;
			width: auto;
			display: block;
			transition: 0.2s ease;



			@include mq(mobile) {
				display: none;
			}

		}
	}

	.btn.btn-primary {
		margin: 25px 0 0;
		padding: 13px 70px;

		@include mq(mobile) {
			padding: 15px 0;
			width: 100%;
			margin: 55px 0 0;
		}

	}
}

input, select, textarea, button, optgroup {
	font-family: $font-standard !important;
}