#projects {

	.section-projects {
		background: $color-neutral;

		.sub-intro {
			text-align: center;
			padding: 65px 0;

			@include mq(mobile) {
				padding: 45px 0;
			}

			h1 {
				font-size: $font-size-h2;
				margin: 0 0 30px;
				letter-spacing: 3px;
				font-weight: $font-weight-bold;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-h4;
					margin: 0 0 20px;
				}
			}

			p {
				font-size: $font-size-large;
				line-height: $line-height-large;
				font-weight: $font-weight-light;
				color: $color-black;
				margin: 0 auto;
				max-width: 900px;

				@include mq(mobile) {
					font-size: $font-size-medilarge;
				}
			}
		}

		.sub-projects {
			padding: 0 0 120px;

		}
	}
}