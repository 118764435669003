.section-award-modal {
	background-color: $color-white;
	display: none;
	margin: 0 20%;
	padding: 4px 3px 0 3px;
	position: fixed;
	top: 12%;
	z-index: 99;

	&.mod-show {
		display: block;
	}

	@include mq(mobile) {
		margin: 0 5%;
		padding: 4px 3px 0 3px;
		top: 21%;
		width: 90%;
	}

	@include mq(tablet) {
		margin: 0 5%;
	}

	img {
		max-width: 100%;
	}

	.close-modal-button {
		background-color: unset;
		border: none;
		padding: 0;
		position: absolute;
		right: 0;
		top: -27px;

		@include mq(mobile) {
			right: 0;
			top: -27px;
		}

		.close-modal-icon {
			cursor: pointer;
			font-size: $font-size-large;
			line-height: $line-height-base;
			text-transform: none;
		}

		.close-modal-icon:before {
			color: $color-white;
			content: "\2715";
			line-height: $line-height-base;
			speak: none;
		}
	}
}

.sub-modal-background {
	background-color: rgba($color-black, 0.5);
	display: none;
	height: 100vh;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 98;

	&.mod-show {
		display: block;
	}
}
