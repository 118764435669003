ul.list-projects {
	@extend .list-default;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	li.item-projects {
		width: calc(50% - 15px);
		margin: 0 0 30px;
		position: relative;
		background: $color-white;
		box-shadow: 0 0 35px 0 rgba($color-black,0.40);

		@include mq(mobile) {
			margin: 0 0 15px;
			width: 100%;
			box-shadow: none;
		}

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 3px;
			background: linear-gradient(to left, $color-neutral-dark 50%, $color-primary 50%);
			background-size: 200% 100%;
			background-position:right bottom;
			transition:all 1s ease;

			@include mq(mobile) {
				background: $color-primary;
			}
		}

		&:hover:after {
			background-position: left bottom;
		}

		.sub-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}

		.sub-image {
			width:  100%;

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		.sub-copy {
			padding: 35px 0 25px;
			text-align: center;

			@include mq(mobile) {
				padding: 30px 20px;
			}

			h3 {
				font-weight: $font-weight-bold;
				margin: 0 0 10px;

				@include mq(mobile) {
					font-size: $font-size-h4;
					margin: 0 0 15px;
				}
			}

			h4,
			h5{
				font-weight: $font-weight-semibold;
				font-size: $font-size-medismall;
				color: $color-text;
				margin: 0 0 10px;
				letter-spacing: 1px;

			}

			h5 {
				font-weight: $font-weight-light;
				margin: 0;
			}
		}
	}
}