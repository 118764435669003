#blog {

	.section-intro {
		width: 94%;
		padding: 70px 0 60px 0;

		h1 {
			margin: 0;
			float: left;
			font-size: $font-size-h1;
		}

		ul.list-filters {
			float: right;
			list-style: none;
			margin: 20px 0 0 0;
			padding: 0;
			line-height: 1;
			font-weight: $font-weight-semibold;
			text-transform: uppercase;

			li {
				margin: 0 15px 0 0;
				padding: 0 15px 0 0;
				border-right: 1px solid $color-borders;
				display: inline-block;

				&.item-title {
					margin-right: 10px;
					padding-right: 0;
					border-right: none;
				}

				&:last-child {
					margin-right: 0;
					padding-right: 0;
					border-right: none;
				}
			}
		}
	}

	.section-related ul.list-posts {
		max-width: 1200px;
	}
}