// Clearfix
.clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

// Remove selectability
.noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

// Remove focus styles
.nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

// Default list styles
.list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}
}