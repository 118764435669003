// media query utility classes

.mq-mobile {
	content: "#{$mq-string-mobile}";
}

.mq-desktop {
	content: "#{$mq-string-desktop}";
}

.mq-tablet {
	content: "#{$mq-string-tablet-only}";
}

.mq-tablet-up {
	content: "#{$mq-string-tablet-up}";
}

.mq-tablet-down {
	content: "#{$mq-string-tablet-down}";
}

.mq-landscape {
	content: "#{$mq-string-landscape}";
}

.mq-portrait {
	content: "#{$mq-string-portrait}";
}

.mq-retina {
	content: "#{$mq-retina}";
}