// BRAND DEFAULTS SECTION //
// If failed to find site specific branding - Use mwd-toolkit default branding

/* Whether to pass mixin params to create default styles (e.g. for docs) */
$generate-default-styles: false !default;

/* COLOURS */
$color-black: #000000 !default;
$color-white: #ffffff !default;

$color-body: $color-white !default;

$color-neutral: #f0f0f0 !default;
$color-neutral-dark: #607D8B !default;

$color-borders: #7f8c8d !default;
$color-borders-light: #bdc3c7 !default;

$color-text: #424242 !default;
$color-text-light: #959595 !default;

$color-headings: #263238 !default;

$color-error: #ff0000 !default;

$color-primary: #1abc9c !default;
$color-secondary: #039BE5 !default;

$color-buttons: $color-primary !default;

$color-link: $color-primary !default;
$color-link-hover: highlight-by($color-link, 5%) !default;
$color-link-active: $color-link-hover !default;

$color-validation-success: $color-primary !default;
$color-validation-error: $color-error !default;

/* FONTS */

$font-standard: Arial, Helvetica, Sans-serif !default;

$font-size-base: 10px !default;
$font-size-standard: 1.4rem !default; // 14px
$font-size-tiny: 1rem !default; // 10px
$font-size-small: 1.2rem !default; // 12px
$font-size-med: 1.6rem !default; // 16px
$font-size-large: 1.8rem !default; // 18px
$font-size-larger: 2.1rem !default; // 21px
$font-size-largest: 3rem !default; // 30px

$font-size-h1: 3.6rem !default; // 36px
$font-size-h2: 3rem !default; // 30px
$font-size-h3: 2.4rem !default; // 24px
$font-size-h4: $font-size-large !default; // 18px
$font-size-h5: $font-size-med !default; // 16px
$font-size-h6: $font-size-standard !default; // 14px

$font-size-hero-standard: $font-size-larger !default; // 21px
$font-size-hero-small: 2rem !default; // 20px
$font-size-hero-med: 5rem !default; // 50px
$font-size-hero-large: 6.5rem !default; // 65px

$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 700 !default;

$font-weight-default: $font-weight-light !default;

$headings-font: $font-standard !default;
$headings-font-weight: $font-weight-light !default;
$headings-color: $color-headings !default;
$headings-line-height: 1.4 !default;

$line-height-base: 1.55 !default;
$line-height-large: 2 !default;

/* STRUCTURE */

$viewport-width: 100% !default;
$container-width: 90% !default;
$container-width-mobile: 100% !default;
$container-pad-mobile: 5px !default;

/* PAGE CONTROL STYLING */
$radius-form: 0px !default;
$radius-input: $radius-form !default;
$radius-textarea: $radius-form !default;
$radius-select: $radius-form !default;

$radius-button: 5px !default;
$padding-button: 12px !default;
$text-size-button: $font-size-standard !default;

$border-select-separator: 1px solid $color-borders-light !default;
$height-select-separator: 50% !default;
$character-select-arrow: '' !default;
$font-family-select-arrow: none !default;

/* MEDIA QUERIES */
$container-max-width: 1250px !default;

$mq-desktop-size: $container-max-width !default;
$mq-tablet-size: 1024px !default;
$mq-mobile-size: 768px !default;

$section-spacing: 90px;
$section-spacing-mobile: 30px;

// END BRAND DEFAULTS SECTION //