.btn {

	&.btn-primary {
		border-radius: 0;
		font-size: $font-size-standard;
		font-weight: $font-weight-bold;
		padding: 11px 50px;
		letter-spacing: 3px;
		text-transform: uppercase;
		box-shadow: none !important;
		border: none !important;

		@include mq(mobile) {
			width: 100%;
			padding: 15px 0;
			text-align: center;
		}
	}
}