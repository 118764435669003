#about {

	.section-hero {
		padding: 60px 0 40px;

		@include mq(mobile) {
			padding: 40px 5px;
		}

		h1 {
			font-size: $font-size-h2;
			margin: 0 0 30px;
			letter-spacing: 3px;
			font-weight: $font-weight-bold;
			text-transform: uppercase;

			@include mq(mobile) {
				font-size: $font-size-h4;
				margin: 0 0 20px;
			}
		}

		p {
			font-size: $font-size-large;
			line-height: $line-height-large;
			font-weight: $font-weight-light;
			color: $color-black;
			margin: 0;
			max-width: 900px;

			@include mq(mobile) {
				font-size: $font-size-medilarge;
			}
		}
	}

	.section-intro {
		min-height: 550px;
		position: relative;

		@include mq(mobile) {
			min-height: 0;
		}

		.sub-background {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 550px;
			z-index: 0;
			background-position: center;
			background-size: cover;

			@include mq(mobile) {
				position: relative;
				width: 100%;
				height: 250px;
			}
		}

		.sub-copy {
			position: relative;
			z-index: 1;
			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				flex-direction: column;
			}

			.sub-left {
				margin: 0 55px 0 0;
			}

			.sub-left,
			.sub-right {
				max-width: 495px;

				@include mq(mobile) {
					max-width: none;
					margin: 0;
				}

				p {
					font-size: $font-size-medismall;
					line-height: $line-height-large;
				}
			}
		}
	}

	.section-team {
		text-align: center;
		padding: 115px 0;

		@include mq(mobile) {
			padding: 90px 0 25px;
		}

		h2 {
			font-size: $font-size-h5;
			margin: 0 0 80px;
			letter-spacing: 3px;
			font-weight: $font-weight-bold;
			text-transform: uppercase;

			@include mq(mobile) {
				margin: 0 0 60px;
			}
		}

		ul.list-team {
			@extend .list-default;
			display: flex;
			justify-content: space-between;
			text-align: left;

			@include mq(mobile) {
				flex-direction: column;
			}

			li.item-team {
				max-width: 220px;
				padding: 0 20px 0 0;

				@include mq(mobile) {
					width: 100%;
					max-width: 345px;
					text-align: center;
					margin: 0 auto 75px;
				}

				.sub-image {
					margin: 0 0 32px;

					@include mq(mobile) {
						margin: 0 0 25px;
					}

					img {
						display: block;
						width: 100%;
						height: auto;
						max-width: 190px;

						@include mq(mobile) {
							margin: 0 auto;
						}
					}
				}

				ul.list-info {
					@extend .list-default;
					margin: 0 0 20px;

					li {

						&:not(:last-child) {
							margin: 0 0 4px;
						}

						h4 {
							font-weight: $font-weight-semibold;
							margin: 0;
						}

						a, h5 {
							font-size: $font-size-standard;
							font-weight: $font-weight-light;
							margin: 0;
						}
					}
				}

				.sub-copy {
					margin: 0 0 35px;
					max-height: 120px;
					overflow: hidden;
					transition: max-height 0.3s ease;

					@include mq(mobile) {
						max-height: 70px;
						margin: 0 0 40px;
					}

					&.mod-more {
						max-height: 400px;
					}
					
					p {
						margin: 0;
						line-height: $line-height-med;
					}
				}
				
				.btn.btn-primary {
					padding: 10px 30px;
					font-size: $font-size-tiny;

					@include mq(mobile) {
						padding: 13px 30px;
						display: inline;
					}
				}
			}
		}
	}
}