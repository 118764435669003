#contact {

	.section-hero {
		padding: 65px 0;
		text-align: center;
		max-width: 830px !important;

		@include mq(mobile) {
			padding: 45px 0;
		}

		h1 {
			font-size: $font-size-h2;
			margin: 0 0 30px;
			letter-spacing: 3px;
			font-weight: $font-weight-bold;
			text-transform: uppercase;

			@include mq(mobile) {
				font-size: $font-size-h4;
				margin: 0 0 20px;
			}
		}

		p {
			font-size: $font-size-large;
			line-height: $line-height-large;
			font-weight: $font-weight-light;
			margin: 0 0 30px;

			@include mq(mobile) {
				font-size: $font-size-medilarge;
				margin: 0 0 40px;
			}
		}

		ul.list-contact {
			@extend .list-default;
			display: flex;
			justify-content: center;

			@include mq(mobile) {
				flex-direction: column;
				align-items: center;
			}

			li {
				font-size: $font-size-large;

				@include mq(mobile) {
					font-size: $font-size-med;
				}

				&:first-child {
					margin: 0 20px 0 0;

					@include mq(mobile) {
						margin:  0 0 15px;
					}
				}

				a {
					font-weight: $font-weight-medium;
					font-size: $font-size-hero-small;

					@include mq(mobile) {
						font-size: $font-size-h2;
					}

					&[href^="tel:"] {
						color: $color-text;
					}

				}
			}
		}
	}

	.section-form {
		text-align: center;
		margin: 0 0 80px;

		form {
			max-width: 700px !important;

			header {
				margin: 0 0 30px;

				@include mq(mobile) {
					margin: 0 0 50px;
				}

				h2 {
					font-size: $font-size-h5;
					letter-spacing: 3px;
					font-weight: $font-weight-bold;
					text-transform: uppercase;
					margin: 0;
				}
			}
		}
	}

	.section-map {

		.sub-copy {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 0 45px;

			@include mq(mobile) {
				text-align: center;
				margin: 0 0 35px;
			}

			h3 {
				font-size: $font-size-large;
				font-weight: $font-weight-semibold;
				margin: 0 0 10px;
			}

			h4 {
				font-size: $font-size-large;
				font-weight: $font-weight-light;
				margin: 0;

				@include mq(mobile) {
					line-height: $line-height-med;
				}
			}
		}

		#map {
			width: 100%;
			height: 405px;

			@include mq(mobile) {
				height: 355px;
			}
		}
	}
}