#project-page {

	.section-hero {
		height: 380px;
		width: 100%;
		background-size: cover;
		background-position: center;

		@include mq(mobile) {
			height: 190px;
		}
	}

	.section-intro {
		padding: 85px 75px;

		@include mq(mobile) {
			padding: 30px 0 40px;
		}

		h1 {
			font-weight: $font-weight-regular;
			margin: 0 0 30px;

			@include mq(mobile) {
				font-size: $font-size-h2;
			}
		}

		.sub-copy {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;

			@include mq(mobile) {
				flex-direction: column;
			}

			ul.list-details {
				@extend .list-default;

				@include mq(mobile) {
					margin: 0 0 40px;
				}

				li {
					font-weight: $font-weight-regular;
					font-size: $font-size-med;
					min-width: 230px;
					padding: 15px 0;
					border-bottom: 1px solid $color-borders-light;
					display: flex;
					align-items: center;
					color: $color-black;

					@include mq(mobile) {
						font-size: $font-size-standard;
						padding: 8px 0;
					}

					&:first-child {
						padding: 0 0 15px;

						@include mq(mobile) {
							padding: 0 0 8px;
						}
					}

					.svg-icon {
						margin: 0 15px 0 0;
					}

					span {
						display: inline-block;
						font-weight: $font-weight-light;
						font-size: $font-size-medismall;
						color: $color-text;
						width: 85px;
						text-transform: uppercase;

						@include mq(mobile) {
							font-size: $font-size-small;
							width: 65%;
							display: inline-block;
							position: relative;
						}
					}
				}
			}

			.sub-text {
				max-width: 715px;

				p {
					font-size: $font-size-med;
					line-height: $line-height-large;

					@include mq(mobile) {
						font-size: $font-size-medismall;
					}
				}
			}
		}
	}

	.section-gallery {
		margin: 0 0 125px;

		@include mq(mobile) {
			margin: 0 0 90px;
		}

		ul.list-gallery {
			@extend .list-default;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			@include mq(mobile) {
				flex-direction: column;
			}

			li {
				width: calc(50% - 5px);
				margin: 0 0 10px;

				@include mq(mobile) {
					width: 100%;
				}

				img {
					width:  100%;
					height: auto;
					display: block;
				}
			}
		}
	}
}