ul.list-facebook-posts {
	@extend .list-default;
	display: flex;
	justify-content: space-between;

	@include mq(mobile) {
		flex-wrap: wrap;
	}

	li {
		width: calc(50% - 15px);
		padding: 15px 20px 20px 40px;
		box-shadow: 0 0 20px 0 rgba(0,0,0,.2);

		@include mq(mobile) {
			width: 100%;
			padding: 15px 15px 25px;
			margin: 0 0 15px;
		}

		.sub-mob-image {
			display: none;

			@include mq(mobile) {
				display: block;
				margin: 0 0 15px;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.sub-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 0 20px;

			.sub-info {
				display: flex;
				align-items: center;

			}

			img {
				display: inline-block;
				height: 100%;
				width: auto;
				max-height: 55px;
			}

			h3 {
				display: inline-block;
				margin: 0 0 0 20px;
				text-transform: uppercase;
				font-size: $font-size-med;
				font-weight: $font-weight-bold;
			}
		}

		.sub-post-content {
			display: flex;
			justify-content: space-between;

			.sub-copy {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				max-width: 255px;
				color: $color-black;

				@include mq(mobile) {
					max-width: none;
				}

				h5 {
					font-size: $font-size-small;
					color: $color-neutral-dark;
					margin: 0;
				}
			}

			img {
				width:  100%;
				height: auto;
				display: inline-block;

				@include mq(mobile) {
					display: none;
				}
			}
		}
	}
}