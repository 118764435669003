@mixin tk-intro-copy(
	$font-size-copy: $font-size-med,
	$font-size-title:  $font-size-h1/$font-size-med+rem,
	$line-height-copy: $line-height-large
) {
	padding: 60px 0;
	text-align: center;
	font-size: $font-size-copy;
	line-height: $line-height-copy;



	h1 {
		font-size: $font-size-title;
	}
}

@mixin tk-intro-copy-mod-small(
	$width-wrapper: 75%
) {
	& .wrap-content {
		width: $width-wrapper;
		margin: 0 auto;
	}
}

.section-intro-copy {
	@include tk-intro-copy;

	&.mod-small {
		@include tk-intro-copy-mod-small;
	}
}