/* Declare MQ strings
 * You can override them for deep voodoo, but we don't really
 * want to encourage such tomfoolery, so they aren't in the brand defaults
 */

$mq-string-mobile: "(max-width: #{$mq-mobile-size})" !default;

$mq-string-tablet-down: "(max-width: #{$mq-tablet-size})" !default;
$mq-string-tablet-up: "(min-width: #{$mq-mobile-size})" !default;
$mq-string-tablet-only: "#{$mq-string-tablet-down} and #{$mq-string-tablet-up}" !default;

$mq-string-desktop: "(min-width: #{$mq-desktop-size})" !default;

$mq-string-portrait: "(orientation: portrait)";
$mq-string-landscape: "(orientation: landscape)";

$mq-retina: "(min-device-pixel-ratio: 2)";

$mq-defs: (
	mobile: $mq-string-mobile,
	desktop: $mq-string-desktop,
	tablet: $mq-string-tablet-only,
	tablet-down: $mq-string-tablet-down,
	tablet-up: $mq-string-tablet-up,
	landscape: $mq-string-landscape,
	portrait: $mq-string-portrait,
	retina: $mq-retina,
	plus: " or ",
);

@mixin tk-container-mq(
	$container-width: $container-width,
	$container-max-width: $container-max-width,
	$responsive-container: true
) {
	@if (not $responsive-container) {
		width: $container-width !important;
		max-width: $container-max-width !important;
		margin: 0 auto;
	} @else {
		width: $container-width-mobile !important;
		padding-left: $container-pad-mobile;
		padding-right: $container-pad-mobile;
	}
}

.container {
	@include tk-container-mq($responsive-container: false);

	@include mq(mobile) {
		@include tk-container-mq();
	}
}