.form-element {
	@include clearfix;
	min-height: 50px;
	margin: 8px 0;
	position: relative;

	label {
		width: 31.5%;
		display: inline-table;
		vertical-align: middle;
	}

	input[type="text"],
	input[type="password"] {
		width: 68%;
		height: 50px;
		padding: 0 10px;
		border: 1px solid $color-borders;
		line-height: 50px;
		display: inline-table;
		vertical-align: middle;
		background: $color-white;
		border-radius: $radius-input;

		&:focus {
			outline: none;
			border-color: $color-primary;
		}
	}

	textarea {
		width: 68%;
		height: 170px;
		padding: 8px;
		border: 1px solid $color-borders;
		display: inline-table;
		vertical-align: middle;
		background: $color-white;
		border-radius: $radius-textarea;
		resize: none;

		&:focus {
			outline: none;
			border-color: $color-primary;
		}
	}

	select {
		width: 68%;
		height: 50px;
		padding: 0 10px;
		border: 1px solid $color-borders;
		line-height: 50px;
		display: inline-table;
		vertical-align: middle;
		background: $color-white;
		border-radius: $radius-select;

		&::focus {
			outline: none;
			border-color: $color-primary;
		}
	}

	.value-text {
		width: 66%;
		padding: 0 10px;
		display: inline-table;
		vertical-align: middle;
	}

	&.mod-inline {

	}

	&.mod-full {

		label,
		input[type="text"],
		input[type="password"],
		textarea,
		select {
			width: 100%;
		}

		label {
			margin-bottom: 8px;
		}
	}

	&.mod-select {
		@include clearfix;
		position: relative;

		&.mod-unlabelled {
			padding-left: 0;
		}

		&.mod-transparent select {
			background: transparent;
		}

		select {
			padding-right: 40px;
			-webkit-appearance: none;
			-moz-appearance: none;

			&::-ms-expand {
				display: none;
			}
		}

		&::before {
			width: 0;
			height: $height-select-separator;
			margin-right: 25px;
			content: "";
			border-left: $border-select-separator;
			position: absolute;
			top: 25%;
			right: 20px;
		}

		&::after {
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid $color-borders;
			display: block;
			pointer-events: none;
			position: absolute;
			top: 22px;
			right: 20px;
		}
	}

	&.mod-check {
		margin-top: 10px;
		padding: 0 0 0 40px;
		position: relative;

		label {
			@include clearfix;
			width: 100%;
			height: auto;
			margin-left: 5px;
			
			line-height: 1;
			text-align: left;
			cursor: pointer;
			position: relative;
			top: 12px;
		}

		input[type=checkbox] {
			display:none;

			& + label::before {
				width: 25px;
				height: 25px;
				border: none;
				line-height: 26px;
				background: $color-neutral;
				color: $color-white;
				cursor: pointer;
				overflow: visible;
				content: '';
				position: absolute;
				left: -45px;
				top: -7px;
				border-radius: 50%;
				transition: all 0.1s ease;
			}

			& + label::after {
				width: 4px;
				height: 9px;
				display: block;
				border: solid $color-white;
				border-width: 0 2px 2px 0;
				content: '';
				transform: rotate(45deg);
				position: absolute;
				left: -35px;
				top: -1px;
			}

			&:checked + label::before {
				background: $color-validation-success;
			}
		}
	}

	&.mod-value {
		padding: 10px 0px;

		label {
			line-height: $font-size-base;
		}

		.input-value {
			width: 68%;
			display: inline-block;
			line-height: $font-size-base;
		}
	}

	&.mod-validation-error {

		label {
			color: $color-validation-error;
		}

		input,
		select,
		textarea {
			border-color: $color-validation-error;
		}
	}

	&.mod-validation-success {

		label {
			color: $color-validation-success;
		}

		input,
		select,
		textarea {
			border-color: $color-validation-success;
		}
	}

	&.mod-valign-top {

		& > * {
			vertical-align: top;
		}
	}

	&.mod-valign-bottom {

		& > * {
			vertical-align: bottom;
		}
	}
}